<template>
    <div>
        <div class="header-section sinupheader-section">
            <div class="container">
                <div class="row login">
                    <div class="col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-md-8 login-inputs mb-3">
                                <login-input></login-input>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Banner Section--->
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>Restaurant Employee Development Training</h2>
                    <p>
                        Equip Your Team with Essential Skills and Knowledge Through CRA's Restaurant Employee Development Training and Ensure Compliance with California’s Sexual Harassment Prevention Training Standards
                    </p>
                </div>
            </div>
        </section>
        <!--Banner Section--->
        <!---Main Content--->
        <div class="content-area">
            <div class="container">
                <div class="row pt-6 pb-6">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="training-card">
                            <div class="training-img">
                                <img src="img/signup/image1.png" class="img-fluid" />
                            </div>
                            <div class="training-content">
                                <h3>Individual Sign Up</h3>
                                <router-link class="btn ctm-btn" to="/user_register">Click Here</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="training-card">
                            <div class="training-img">
                                <img src="img/signup/image2.png" class="img-fluid" />
                            </div>
                            <div class="training-content">
                                <h3>Company Sign Up</h3>
                                <router-link class="btn ctm-btn" to="/register">Click Here</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="training-card">
                            <div class="training-img">
                                <router-link to="/sexual_harassment"> <img src="img/signup/image3.png" class="img-fluid" /></router-link>
                            </div>
                            <div class="training-content">
                                <router-link to="/sexual_harassment"><h3>Sexual Harassment Prevention Training</h3></router-link>
                                <router-link class="btn ctm-btn" to="/user_register?course_type=sexual_harrasment">Individuals</router-link>
                                <router-link class="btn ctm-btn companies" to="register?course_type=sexual_harrasment">Companies</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---Main Content end--->
    </div>
</template>
<script>
import LoginInput from "@/views/Pages/LoginInput.vue";
import "sweetalert2/src/sweetalert2.scss";
import BaseInput from "../../components/Inputs/BaseInput.vue";

export default {
    components: { BaseInput, LoginInput },
    data() {
        return {};
    },
    created: function() {},
    methods: {}
};
</script>
<style scoped>
.ctm-btn {
    background-color: #ef8f1d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}
.login-inputs {
    padding-top: 20px;
}
.login{
    margin-right: 0px !important;
}
</style>
